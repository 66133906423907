import React from 'react'
import './Footer.css'

import Logo from './Logo'
import { Link } from 'gatsby'

export default () => (
  <div>
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-logo">
            <Link to="/" className="site-logo">
              <Logo />
              <h2>Langfjordrennet</h2>
            </Link>
          </div>
          <div className="footer-row">
            <div className="footer-column">
              <h2 className="footer-headline">
                Verdens nordligste fjelltelemarksrenn
              </h2>
            </div>
            <div className="footer-column">
              <Link className="footer-link" to="/om-rennet">
                Om rennet
              </Link>
              <Link className="footer-link" to="/resultatlister">
                Resultatlister
              </Link>
              <a
                className="footer-link"
                href="https://signup.eqtiming.no/?Event=langfjordrennet"
              >
                Påmelding
              </a>
            </div>
            <div className="footer-column">
              <a
                className="footer-link"
                href="https://www.facebook.com/langfjordrennet/"
              >
                Facebook
              </a>
              <span className="footer-link">
                E-post:{' '}
                <a className="footer-link" href="mailto:torekarlst@gmail.com">
                  torekarlst@gmail.com
                </a>
              </span>
              <span className="footer-link">
                Tlf:{' '}
                <a className="footer-link" href="tel:41308627">
                  +47 975 70 080
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="taCenter">
          © {new Date().getFullYear()} Langfjordrennet
        </div>
      </div>
    </footer>
  </div>
)
